.main {
    width: 100%;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.308);
    /* display: grid; */
    /* grid-template-areas:
        "spac" 
        "control"; */
  }

  @media screen and (min-width: 65em) {
    .main {
      /* grid-template-areas: */
        /* "spac control"; */
    }
  }

  .overlay-text {
    align-self: start;
    grid-column: 1;
    grid-row: 1;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.308);
    padding: 5px;
    font-size: 1rem;
    line-height: 1.7;
  }
  

  .main-canvas{
    width: 90%;
    height: 80vh;
    background-color: #01040c;
    grid-area: spac;
    display: grid;
  }
  
  
  .control {
    width: 90%;
    grid-area: control;
  }
 













  .center {
    display: flex;
    justify-content: center;
    /* align-items: center;
    height: 200px;
    border: 3px solid green;  */
  }
  
  .btn{
      background-color: #5f78e6;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  